.aboutCompanyText {
  margin-bottom: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid #0c3220;
}
.footer-section.footer-bg {
  background-color: #e7e7e7 !important;
}

.aboutCompanyText h3 {
  font-size: 17px;
  color: #000;
  text-transform: uppercase;
}

.aboutCompanyText p {
  color: black;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.facebook {
  background-color: #3c579e;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.twitter {
  background-color: #1cb8ec;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.instagram {
  background-color: #ec0a68;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.whatsapp {
  background-color: #1bd741;
}

@media (max-width: 7680px) {
  .aboutCompanyText,
  .single-footer-widget {
    text-align: center;
  }
  .aboutCompanyText {
    padding: 0 15px 15px 15px;
  }

  .footer-info-contact {
    text-align: left;
  }

  .copyright-area .copyright-area-content {
    text-align: center;
  }

  .copyright-area .copyright-area-content ul {
    text-align: center;
  }

  .single-footer-widget .footer-info-contact {
    justify-content: center;
    text-align: center;
    display: flex;
  }

  .single-footer-widget .footer-info-contact .topIcon svg {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .footer-contact {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
  }
  .single-footer-widget .footer-info-contact {
    margin-bottom: 0 !important;
  }
}
