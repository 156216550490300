.productsCategorySec {
    padding: 5px 0;
    /* background-color: #e3ffcf; */
    /* background: url("../../../assets/img/admclick/back.webp") no-repeat bottom / cover; */
}

.productsCategoryItem {
    overflow: hidden;
    border-radius: 5px;
}
.categoryBody{
    border-radius: 5px;
}

.categoryHeader {
    background-color: #e7e7e7;
    color: #000;
    padding: 10px;
}
.differentbackground{
    background-color:#1f1f2a !important;
    color: white !important;
  
}
.diffrentcolor{
    color: white;
    background-color: #1f1f2a;
    
}

.categoryHeader .title {
    text-transform: capitalize;
    width: 100%;
    color: #000;
}
.textcenter{
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.categoryHeader a {
    display: inline-block;
    overflow: hidden;
    z-index: 1;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.6s;
    font-size: 12px;
    color: #959595;
    padding: 2px 7px;
    border-radius: 4px;
    transition: .4s ease-in-out;
}

.categoryHeader a:hover {
    
}



.categoryBody .singleProducts {
    width: 100%;
    /* margin-bottom: 30px; */
}

.singleProducts a {
    display: block;
    position: relative;
}

.singleProducts a p {
    color: #444;
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
    line-height: 24px;
    display: none;
}

.singleProducts a img {
    /* border-radius: 8px; */
    /* border: 1px solid #c1c1c1; */
    transition: .4s ease-in-out;
}

.singleProducts a img:hover {
    transform: scale(1.1);
}

.singleProducts .sale {
    display: inline-block;
    position: absolute;
    top: -10px;
    z-index: 1;
    padding: 2px 10px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    right: 10px;
    text-align: center;
    background: #fb641b;
    display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }

    .categoryBody .singleProducts {
        width: 100%;
    }
}