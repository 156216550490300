.abaris-nav .navbar.navbarItem {
    box-shadow: none;
    padding: 0;
    border-top: 1px solid #efefef;
    border-radius: 0;
}

/* header.sticky{
    transform: translateY(0px);
    background: #fff;
    position: relative;
    z-index: 1;
} */
header.sticky.active {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 11;
    transform: translateY(-33px);
    transition: .4s ease-in-out;
    box-shadow: 0px 15px 10px -15px #a9a9a9;
}